import React, {createContext, useState} from "react";

export const websiteContext = createContext();

export const WebsiteProvider = ({ children }) => {
    const [AppDownloadModalState, changeAppDownloadModalState] = useState(false);

    const updateAppDownloadModalState = (modalState) => {
        changeAppDownloadModalState(modalState);
    };

    return (
        <websiteContext.Provider value={{ AppDownloadModalState, updateAppDownloadModalState }}>
            {children}
        </websiteContext.Provider>
    );
};

