import React from 'react';
import './Button.css';
import { Link } from 'react-router-dom';
import { click } from '@testing-library/user-event/dist/click';

const ButtonContainer = ({ to, bgColor, textColor, rounded, text, scale, onButtonClick}) => {
  const linkStyle = {
    backgroundColor: bgColor,
    color: textColor,
    borderRadius: rounded ? '31.35px' : '0px', // Handle rounded condition
    transform: `scale(${scale})`, // Apply scaling transformation
    display: 'inline-block', // Ensure it's inline for button styling
    padding: '10px 20px', // Add some padding for better button appearance
    textAlign: 'center', // Center the text within the button
    textDecoration: 'none', // Remove underline from Link
  };




  if (onButtonClick != undefined) {
    return (
      <div className="button-link" style={linkStyle} onClick={onButtonClick}>
        {text}
      </div>
    );
  } else {
    return (
      <Link to={to} className="button-link" style={linkStyle}>
        {text}
      </Link>
    );
  }


    // return (
    //   <Link to={to} className="button-link" style={linkStyle} onClick={onButtonClick}>
    //     {text} {/* Display the text passed as a prop */}
    //   </Link>
    // );
}


export default ButtonContainer;
