import React, {useContext, useRef} from "react";

import "./ApplicationDownloadModel.css";  
import { websiteContext } from "../WebsiteContext";

const AppDownloadModal = () => {
    const {updateAppDownloadModalState} = useContext(websiteContext);
    const modalRef = useRef(null);
    const dropDownRef = useRef(null);
    const emailRef = useRef(null);
    const submitButtonRef = useRef(null);

    function handleClose() {
        // console.log("Closing Modal");
        const submitButton = submitButtonRef.current;
        submitButton.disabled = false;
        updateAppDownloadModalState(false);
    }

    function handleSubmit() {
        // console.log("Submitting form");
        const email = emailRef.current.value;
        const gpu = dropDownRef.current.value;
        const submitButton = submitButtonRef.current;

        // Email validation using a simple regex pattern
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email)) {
            alert('Please enter a valid email address.');
            return;
        }

        if(gpu === "No GPU") {
            alert("Having a GPU is Compulsory To Install Our Application");
            handleClose();
            return;
        }

        submitButton.disabled = true;

        fetch('https://neuralperk.tech/api/submitSurveyForm', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, gpu }),
        })
        .then(response => response.json())
        .then(data => {
            // console.log('Success:', data);
            alert("Thank you for your submission");

            const link = document.createElement('a');
            link.href = 'https://github.com/Nicky9319/UserApplication_UpdateRepo/releases/download/v1.0.5/NeuralPerk-Setup-1.0.5.exe';
            // link.href = 'https://objects.githubusercontent.com/github-production-release-asset-2e65be/859005151/5b8d99c8-c019-4ae0-a6c7-7bca24c4db91?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=releaseassetproduction%2F20241015%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20241015T172542Z&X-Amz-Expires=300&X-Amz-Signature=c3f1a645019584756366a18a1ef3b17257a4d81a1f932b414003ca6822512358&X-Amz-SignedHeaders=host&response-content-disposition=attachment%3B%20filename%3DNeuralPerk-Setup-1.0.1.exe&response-content-type=application%2Foctet-stream';
            link.download = 'Neuralperk.exe';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            
            handleClose();
            submitButton.disabled = false;
        })
        .catch(error => {
            console.error('Error:', error);
            alert('An unexpected error occurred.');
        });
    }

    return (
        <div className="overlay">
            <div className="modalHolder">
            <section className="download-app-modal" ref={modalRef}>
                <h2>Select Your GPU</h2> 
                <input 
                    type="email" 
                    id="email" 
                    placeholder="Enter your email" 
                    required 
                    style={{"border": "1px solid rgba(0, 0, 0, 0.7)"}}
                    ref={emailRef}
                />

                <select id="options" ref={dropDownRef} className="dropDown" required style={{border : "1px solid rgba(0, 0, 0, 0.7"}}>
                    <option value="No GPU">No GPU</option>

                    <option value="NVIDIA GTX 960">NVIDIA GTX 960</option>
                    <option value="NVIDIA GTX 980">NVIDIA GTX 980</option>
                    <option value="NVIDIA GTX 980 Ti">NVIDIA GTX 980 Ti</option>

                    <option value="NVIDIA GTX 1050">NVIDIA GTX 1050</option>
                    <option value="NVIDIA GTX 1050 Ti">NVIDIA GTX 1050 Ti</option>
                    <option value="NVIDIA GTX 1060">NVIDIA GTX 1060</option>
                    <option value="NVIDIA GTX 1070">NVIDIA GTX 1070</option>
                    <option value="NVIDIA GTX 1080">NVIDIA GTX 1080</option>
                    <option value="NVIDIA GTX 1630">NVIDIA GTX 1630</option>
                    <option value="NVIDIA GTX 1650">NVIDIA GTX 1650</option>
                    <option value="NVIDIA GTX 1650 Super">NVIDIA GTX 1650 Super</option>
                    <option value="NVIDIA GTX 1660">NVIDIA GTX 1660</option>
                    <option value="NVIDIA GTX 1660 Super">NVIDIA GTX 1660 Super</option>
                    <option value="NVIDIA GTX 1660 Ti">NVIDIA GTX 1660 Ti</option>
                    <option value="NVIDIA GTX 1070 Ti">NVIDIA GTX 1070 Ti</option>

                    <option value="NVIDIA Quadro RTX A4000">NVIDIA Quadro RTX A4000</option>
                    <option value="NVIDIA Quadro RTX A5000">NVIDIA Quadro RTX A5000</option>
                    <option value="NVIDIA Quadro RTX A6000">NVIDIA Quadro RTX A6000</option>
                    <option value="NVIDIA RTX Titan">NVIDIA RTX Titan</option>

                    <option value="NVIDIA RTX 2050">NVIDIA RTX 2050</option>
                    <option value="NVIDIA RTX 2060">NVIDIA RTX 2060</option>
                    <option value="NVIDIA RTX 2060 Super">NVIDIA RTX 2060 Super</option>
                    <option value="NVIDIA RTX 2070">NVIDIA RTX 2070</option>
                    <option value="NVIDIA RTX 2070 Super">NVIDIA RTX 2070 Super</option>
                    <option value="NVIDIA RTX 2080">NVIDIA RTX 2080</option>
                    <option value="NVIDIA RTX 2080 Super">NVIDIA RTX 2080 Super</option>
                    <option value="NVIDIA RTX 2080 Ti">NVIDIA RTX 2080 Ti</option>

                    <option value="NVIDA RTX 3050 4GB">NVIDIA RTX 3050 4GB</option>
                    <option value="NVIDA RTX 3050 6GB">NVIDIA RTX 3050 6GB</option>
                    <option value="NVIDA RTX 3050 8GB">NVIDIA RTX 3050 8GB</option>
                    <option value="NVIDIA RTX 3050 Ti [Laptop]">NVIDIA RTX 3050 Ti [Laptop]</option>
                    <option value="NVIDIA RTX 3060">NVIDIA RTX 3060</option>
                    <option value="NVIDIA RTX 3060 [Laptop]">NVIDIA RTX 3060 [Laptop]</option>
                    <option value="NVIDIA RTX 3060 Ti">NVIDIA RTX 3060 Ti</option>
                    <option value="NVIDIA RTX 3070">NVIDIA RTX 3070</option>
                    <option value="NVIDIA RTX 3070 [Laptop]">NVIDIA RTX 3070 [Laptop]</option>
                    <option value="NVIDIA RTX 3070 Ti">NVIDIA RTX 3070 Ti</option>
                    <option value="NVIDIA RTX 3080">NVIDIA RTX 3080</option>
                    <option value="NVIDIA RTX 3080 [Laptop]">NVIDIA RTX 3080 [Laptop]</option>
                    <option value="NVIDIA RTX 3080 Ti">NVIDIA RTX 3080 Ti</option>
                    <option value="NVIDIA RTX 3090">NVIDIA RTX 3090</option>
                    <option value="NVIDIA RTX 3090 Ti">NVIDIA RTX 3090 Ti</option>

                    <option value="NVIDIA RTX 4050">NVIDIA RTX 4050</option>
                    <option value="NVIDIA RTX 4050 [Laptop]">NVIDIA RTX 4050 [Laptop]</option>
                    <option value="NVIDIA RTX 4060">NVIDIA RTX 4060</option>
                    <option value="NVIDIA RTX 4060 Ti">NVIDIA RTX 4060 Ti</option>
                    <option value="NVIDIA RTX 4070">NVIDIA RTX 4070</option>
                    <option value="NVIDIA RTX 4070 Super">NVIDIA RTX 4070 Super</option>
                    <option value="NVIDIA RTX 4070 Ti">NVIDIA RTX 4070 Ti</option>
                    <option value="NVIDIA RTX 4080">NVIDIA RTX 4080</option>
                    <option value="NVIDIA RTX 4090">NVIDIA RTX 4090</option> 
                </select>

                <div className="submitCloseButtons">
                    <button type="submit" id="submitButton" onClick={handleSubmit} ref={submitButtonRef}>Submit</button>
                    <button className="close-btn" onClick={handleClose}>Close</button>
                </div>

            </section>
            </div>
        </div>
    );
};

export default AppDownloadModal;
