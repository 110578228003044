import React from 'react';
import './IconTextSet.css'; // Import the static CSS file

const IconTextSet = ({ imgSrc, linkText, linkUrl }) => {
  return (
    <div className="icon-text-set">
      <img 
        src={imgSrc} 
        alt="" 
        className="icon-img"
      />
      <a href={linkUrl} className="icon-link" target="_blank" rel="noopener noreferrer">
        <p className="icon-text">{linkText}</p>
      </a>

    </div>
  );
};

export default IconTextSet;
