import React from 'react';
import TextContainer from './Partials/TextContainer';

const NotFound = () => {
    return (
        <>
            <section className="bodyContainer" id="four">
                <TextContainer 
                    title="404" 
                    text="Page Not Found"
                    bgColor="#ffffff"
                    transparency={1}
                    titleColor="#000000"
                    textColor="#000000"
                />
            </section>
        </>
    );
};

export default NotFound;
