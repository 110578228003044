import './Landing.css'
import Button from './Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesDown } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useContext } from 'react';

import { websiteContext } from '../../WebsiteContext';

function Landing() {
    const {updateAppDownloadModalState} = useContext(websiteContext);

    useEffect(() => {
        const downloadButton = document.getElementById('download');
        if (downloadButton) {
            downloadButton.addEventListener('click', handleClick);
        }
        return () => {
            if (downloadButton) {
                downloadButton.removeEventListener('click', handleClick);
            }
        };
    }, []);

    const handleClick = () => {
        // console.log('Download App Button Clicked');
        updateAppDownloadModalState(true);
        // const link = document.createElement('a');
        // link.href = 'https://github.com/Nicky9319/UserApplication_UpdateRepo/releases/download/v1.0.3/NeuralPerk-Setup-1.0.3.exe';
        // // link.href = 'https://objects.githubusercontent.com/github-production-release-asset-2e65be/859005151/5b8d99c8-c019-4ae0-a6c7-7bca24c4db91?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=releaseassetproduction%2F20241015%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20241015T172542Z&X-Amz-Expires=300&X-Amz-Signature=c3f1a645019584756366a18a1ef3b17257a4d81a1f932b414003ca6822512358&X-Amz-SignedHeaders=host&response-content-disposition=attachment%3B%20filename%3DNeuralPerk-Setup-1.0.1.exe&response-content-type=application%2Foctet-stream';
        // link.download = 'Neuralperk.exe';
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
    };

    return (
        <>
        <div className="LandinContainer">
            <div className="above">
                <h3>Idle Laptop?</h3>
                <h1><strong>Time to Cash-in</strong></h1>
                <br />
                <div className="buttonContainer" id='download'>
                    <Button
                        text='Download App'
                        textColor='#000000'
                        bgColor='#ffffff'
                        rounded='0.5'
                    />
                </div>
            </div>
            <div className="below">
                <section>
                    Explore
                    <br />
                    <a href="#one"><FontAwesomeIcon icon={faAnglesDown} style={{ color: '#ffffff' }} /></a>
                </section>
            </div>
        </div>
        </>
    );
}

export default Landing;