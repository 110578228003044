import React from 'react';
import './TextContainer.css';

const TextContainer = ({ 
    title, 
    subtitle, 
    text, 
    buttonText, // Prop for button text
    buttonLink, // Prop for button link
    bgColor, 
    titleColor, 
    textColor, 
    transparency,
    width
}) => {
    // Ensure that the transparency value is between 0 and 1
    const alpha = Math.max(0, Math.min(transparency, 1)); // Clamps value between 0 and 1

    const containerStyle = {
        backgroundColor: `rgba(${parseInt(bgColor.slice(1, 3), 16)}, ${parseInt(bgColor.slice(3, 5), 16)}, ${parseInt(bgColor.slice(5, 7), 16)}, ${alpha})`,
        width: width,
    };

    return (
        <section className="container" style={containerStyle}>
            <section className="title">
                <h1 style={{ color: titleColor }}>{title}</h1>
                {subtitle && <h3 style={{textAlign:'left' , color: titleColor }}>{subtitle}</h3>} {/* Display subtitle */}
            </section>
            <section className="text">
                {text ? (
                    <p style={{ color: textColor }} dangerouslySetInnerHTML={{ __html: text }} />
                ) : (
                    <p style={{ color: textColor }}></p>
                )}
            </section>
            {buttonText && buttonLink && (
                <a href={buttonLink} style={{ textDecoration: 'none' }}>
                    <button style={{ marginTop: '10px' }}>
                        {buttonText} {/* Button with the provided text */}
                    </button>
                </a>
            )}
            
            
        </section>
    );
};

export default TextContainer;
