import React from 'react';
import './ButtonTextContainer.css'; // Create a CSS file for styles
import Button from './Button'; // Import your Button component

const ButtonTextContainer = ({ title, subtitle, text, bgColor, titleColor, textColor, transparency, buttonText, buttonLink, buttonColor, buttonTextColor, onButtonClick}) => {
    const alpha = Math.max(0, Math.min(transparency, 1)); // Clamp value between 0 and 1
    const containerStyle = {
        backgroundColor: `rgba(${parseInt(bgColor.slice(1, 3), 16)}, ${parseInt(bgColor.slice(3, 5), 16)}, ${parseInt(bgColor.slice(5, 7), 16)}, ${alpha})`,
        borderRadius: '25px', // Border radius for the container
        padding: '20px', // Add some padding
        marginBottom: '20px', // Space between containers
    };

    return (
        <div className="buttonTextContainer" style={containerStyle}>
            <div className="title">
                <h1 style={{ color: titleColor }}>{title}</h1>
                {subtitle && <h3 style={{ color: titleColor }}>{subtitle}</h3>}
            </div>
            <div className="text" style={{ color: textColor }}>
            {/* Render text as HTML using dangerouslySetInnerHTML */}
                <div dangerouslySetInnerHTML={{ __html: text }} />
            </div>
            <div className="buttonContainer">
                <br /><br />
                {/* Use Link for internal navigation */}
                <Button 
                    to={buttonLink} // Internal link using "to"
                    bgColor={buttonColor}
                    textColor={buttonTextColor}
                    rounded={true}
                    text={buttonText} // Button text passed as a prop
                    scale={1.3}
                    onButtonClick={onButtonClick}
                />
                <br /><br />
            </div>
        </div>
    );
};

export default ButtonTextContainer;
