import React, { useEffect, useState, useContext } from 'react';
import './Services.css';
import TextContainer from './Partials/TextContainer';
import ButtonTextContainer from './Partials/ButtonTextContainer';

import { websiteContext } from '../WebsiteContext';

function Services() {
    const [width, setWidth] = useState('40%');
    const {updateAppDownloadModalState} = useContext(websiteContext);

    const updateWidth = () => {
        setWidth(window.innerWidth <= 768 ? '100%' : '40%');
    };

    useEffect(() => {
        updateWidth();
        window.addEventListener('resize', updateWidth);
        return () => {
            window.removeEventListener('resize', updateWidth);
        };
    }, []);

    function DownloadApplication(){
        // console.log('Download App Button Clicked');
        updateAppDownloadModalState(true);
    }


    return (
        <section className="bodyContainer" id='three'>
            <TextContainer 
                title="Our Services" 
                text="At Neuralperk, we make computing power work smarter for you. Whether you want to earn passive income by sharing your idle devices or need extra resources for demanding tasks, we’ve got the solution. Here’s how:"
                bgColor="#ffffff"
                transparency={0}
                titleColor="#ffffff"
                textColor="#ffffff"
            />
            <ButtonTextContainer 
                title="Earn Money with Your Idle Computer" 
                text="Turn your idle computer into a money-making machine! When your laptop or PC is sitting unused, Neuralperk taps into its resources for tasks like rendering and AI/ML processing. In return, you earn cash for every bit of computing power you share. It's an easy, passive way to make the most out of your tech without lifting a finger."
                bgColor="#ffffff"
                transparency={1}
                titleColor="#000000"
                textColor="#000000"
                buttonColor='#000000'
                buttonText="Download App"
                buttonTextColor='#ffffff'
                onButtonClick={DownloadApplication}
                // buttonLink="https://github.com/Nicky9319/UserApplication_UpdateRepo/releases/download/v1.0.1/NeuralPerk-Setup-1.0.1.exe" // Button link
            />
            <TextContainer 
                title="But There's more!" 
                bgColor="#ffffff"
                transparency={0}
                titleColor="#ffffff"
            />
            <div className="cardsContainer">
                <TextContainer 
                    title="Rendering Services" 
                    text={`
                        <p>Need to render high-quality graphics, animations, or architectural designs, but don’t have the computing power? Neuralperk offers on-demand access to high-performance machines designed to handle your rendering needs with speed and precision.</p>
                        <ul>
                            <li>Perfect for: 3D modeling and animation</li>
                            <li>Video rendering</li>
                            <li>Architectural visualizations</li>
                            <li>Game development projects</li>
                        </ul>
                        <p>Just upload your project, request the power you need, and let our network of powerful machines do the heavy lifting.</p>
                    `}
                    bgColor="#ffffff"
                    transparency={1}
                    titleColor="#000000"
                    textColor="#000000"
                    width={width}
                />
                <br />
                <TextContainer 
                    title="AI/ML Computing Power" 
                    text={`
                        <ul>
                            <li>When your AI/ML projects outgrow your computer’s capacity, Neuralperk provides scalable, high-performance computing resources to run algorithms, train models, and process large datasets.</li>
                            <li>No need to invest in costly hardware—just request the power you need, when you need it.</li>
                        </ul>
                        <p><strong>Ideal for:</strong></p>
                        <ul>
                            <li>Training deep learning models</li>
                            <li>Running machine learning algorithms</li>
                            <li>Big data analytics and simulations</li>
                        </ul>
                        <p>Simply select your computing requirements, upload your task, and our machines will take care of the rest, delivering fast, reliable results.</p>
                    `}
                    bgColor="#ffffff"
                    transparency={1}
                    titleColor="#000000"
                    textColor="#000000"
                    width={width}
                />
            </div>
        </section>
    );
}

export default Services;
