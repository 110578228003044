import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import ButtonContainer from './Button';
// import logo from '../images/image.png'; 

import { websiteContext } from '../../WebsiteContext';
import cropLogo from "../images/cropLogo.png"

const Navbar = () => {

    const {updateAppDownloadModalState} = useContext(websiteContext);

    useEffect(() => {
        const downloadButton = document.getElementById('downloadAppButton');
        if (downloadButton) {
            downloadButton.addEventListener('click', handleClick);
        }
        return () => {
            if (downloadButton) {
                downloadButton.removeEventListener('click', handleClick);
            }
        };
    }, []);


    const handleClick = async () => {
        // console.log('Download App Button Clicked');
        updateAppDownloadModalState(true);
        // console.log('AppDownloadModalState: ', AppDownloadModalState);
        // const link = document.createElement('a');
        // link.href = 'https://github.com/Nicky9319/UserApplication_UpdateRepo/releases/download/v1.0.3/NeuralPerk-Setup-1.0.3.exe';
        // // link.href = 'https://objects.githubusercontent.com/github-production-release-asset-2e65be/859005151/5b8d99c8-c019-4ae0-a6c7-7bca24c4db91?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=releaseassetproduction%2F20241015%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20241015T172542Z&X-Amz-Expires=300&X-Amz-Signature=c3f1a645019584756366a18a1ef3b17257a4d81a1f932b414003ca6822512358&X-Amz-SignedHeaders=host&response-content-disposition=attachment%3B%20filename%3DNeuralPerk-Setup-1.0.1.exe&response-content-type=application%2Foctet-stream';
        // link.download = 'Neuralperk.exe';
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
    };

    return (
        <div className="navbar">
            <section className="brand">
                <Link to="/" className="logo-link">
                    <img 
                        src={cropLogo} 
                        alt="Neuralperk Logo" 
                    />
                    <i><strong id='logoName'>NEURALPERK</strong></i>
                </Link>
            </section>
            <section className="navbar-buttons">

                <div className='downloadAppNavbarButton' id="downloadAppButton">  
                    <ButtonContainer 
                        // to="/"
                        bgColor="black"
                        textColor="white"
                        rounded={true}
                        text="Download App"
                        scale={1}
                    />
                </div>

                <ButtonContainer 
                    to="/About"
                    bgColor='#f8f9fa'
                    textColor="black"
                    rounded={true}
                    text="About Us"
                    scale={1}
                />

                <ButtonContainer 
                    to="/Services"
                    bgColor='#f8f9fa'
                    
                    textColor="black"
                    rounded={true}
                    text="Services"
                    scale={1}
                />
            </section>
        </div>
    );
};

export default Navbar;
