import React from 'react';
import { Link } from 'react-router-dom';

import './Footer.css';
import IconTextSet from './IconTextSet';

import logo from '../images/cropLogo.png'; // Adjust the path as necessary
import insta from '../images/insta.png'; // Adjust the path as necessary
import linkedin from '../images/linkedin.png'; // Adjust the path as necessary
import email from '../images/email.png'; // Adjust the path as necessary


const Footer = () => {

    const openPrivacyPolicy = () => {
        window.open('/privacyPolicy', '_blank');
    };

    return (
        <>
        <div className="footer">
            <section className="socials">
                <section className="followUs">
                    <strong>FOLLOW US</strong>
                    <br />
                    
                    <IconTextSet 
                        imgSrc={linkedin} // Replace with the actual Facebook icon URL
                        linkText="Neuralperk"
                        linkUrl="https://in.linkedin.com/company/neuralperk" // Replace with your Facebook page URL
                    />
                    <IconTextSet 
                        imgSrc={insta} // Replace with the actual Instagram icon URL
                        linkText="@Neuralperk"
                        linkUrl="https://www.instagram.com/neuralperk/" // Replace with your Instagram page URL
                    />
                </section>

                {/* <section style={{width:'20px'}} id='gap'></section> */}
                <section className="contactUs">
                <strong>CONTACT US</strong>
                <br/>
                
                <IconTextSet 
                        imgSrc={email} // Replace with the actual Instagram icon URL
                        linkText="neuralperk@gmail.com"
                        linkUrl="mailto:neuralperk@gmail.com" // Replace with your Instagram page URL
                    />
                    {/* Add contact details or any other relevant content here */}
                </section>
            </section>


            <section className="privacyPolicy">
                {/* <p>hbfr</p> */}
                <a href="/privacyPolicy.html" target='_blank' rel="noopener noreferrer"> Privacy</a>
            </section>

            <section className='imageContainer'>
                <img src={logo} alt="" />
            </section>

        </div>


        <div className='footerSmall'>
            <section className="socialsSmall">
                    <a href="https://in.linkedin.com/company/neuralperk" target='_blank'>
                        <button className='socialIconButton'>
                            <img src={linkedin} alt="" />
                        </button>
                    </a>

                    <a href="https://www.instagram.com/neuralperk/" target='_blank'>
                        <button className='socialIconButton'>
                            <img src={insta} alt="" />
                        </button>
                    </a>

                    <a href="mailto:neuralperk@gmail.com" target='_blank'>
                        <button className='socialIconButton'>
                            <img src={email} alt="" />
                        </button>
                    </a>
            </section>

            <section className="privacyPolicySmall">
                {/* <p>hbfr</p> */}
                <a href="/privacyPolicy.html" target='_blank' rel="noopener noreferrer"> Privacy</a>
            </section>

            <section className='imageContainerSmall'>
                <img src={logo} alt="" />
            </section>
        </div>

        </>
    );
};

export default Footer;
