import React from 'react';
import TextContainer from './Partials/TextContainer';
import Landing from './Partials/Landing.js';
import ButtonTextContainer from './Partials/ButtonTextContainer';
import './Home.css'
// Data for TextContainers
const textContainersData = [
    {
        id: 'faqs',
        title: "FAQ's?",
        subtitle: 'Frequently Asked Questions',
        bgColor: '#cccccc',
        transparency: 0,
        titleColor: '#ffffff',
        textColor: '#ffffff',
    },
    {
        id: 'what-is',
        title: 'What is Neuralperk?',
        text: 'Neuralperk is your go-to platform for turning idle computer power into earnings. We securely connect people who have unused computing resources with those who need them. Whether you want to make money by lending your laptop’s power or access high-performance machines for tasks like rendering or AI/ML, Neuralperk makes it simple, efficient, and safe.',
        bgColor: '#f0f0f0',
        transparency: 0,
        titleColor: '#ffffff',
        textColor: '#ffffff',
    },
    {
        id: 'getting-started',
        title: 'How do I get started?',
        text: 'It’s easy! Just sign up, and you’re ready to explore our services. Whether you\'re offering your unused computer power or need extra for a big task, the platform lets you quickly get involved. You can lend your system\'s resources to earn or request power for demanding tasks like rendering and machine learning—flexible options, all in one place.',
        bgColor: '#f0f0f0',
        transparency: 0,
        titleColor: '#ffffff',
        textColor: '#ffffff',
    },
    {
        id: 'data-security',
        title: 'Is my data secure?',
        text: 'Absolutely. We take security seriously. Your data is protected through encryption and secure protocols every step of the way. We ensure that your information stays private while you make money or access computing power on our platform, so you can focus on the task at hand without worrying about security.',
        bgColor: '#f0f0f0',
        transparency: 0,
        textColor: '#ffffff',
    },
];

function HomePage() {
    return (
        <>
            <Landing/>
            <section className="bodyContainer" id="one">
                <TextContainer 
                    title="Our Mission!" 
                    text="Neuralperk is a pioneering platform designed to bridge the gap between those who need computing power and those who can provide it. Whether you're a researcher, developer, or enthusiast in need of computational resources, Neuralperk connects you with a network of providers willing to share their idle computing capacity. Our mission is to democratize access to computing power, making it more affordable and accessible to everyone."
                    bgColor="#ffffff"
                    transparency={0}
                    titleColor="#000"
                    textColor="#333"
                />

                <ButtonTextContainer 
                    title="Need Extra Computing Power?" 
                    subtitle="We've Got You Covered!" 
                    text="When your project demands more power than your computer can handle, Neuralperk is here to help. Whether you're working on rendering complex graphics or running AI/ML algorithms, you can tap into our network of high-performance machines to get the job done fast and efficiently."
                    bgColor="#000000"
                    transparency={1}
                    titleColor="#ffffff"
                    textColor="#ffffff"
                    buttonColor='#ffffff'
                    buttonText="Our Services"
                    buttonTextColor='#000000'
                    buttonLink="/Services" // Button link
                />
            </section>

            <section className="bodyContainer" id="two">
                {textContainersData.map(({ id, ...props }) => (
                    <TextContainer key={id} {...props} />
                ))}
            </section>
        </>
    );
}

export default HomePage;
