import React from 'react';
import './About.css';
import TextContainer from './Partials/TextContainer';
// import ButtonTextContainer from './Partials/ButtonTextContainer';
function About() {
    return (
        <>
            <section className="bodyContainer" id="four">
            <TextContainer 
                title="About Us" 
                text="At Neuralperk, we make computing power work smarter for you. Whether you want to earn passive income by sharing your idle devices or need extra resources for demanding tasks, we’ve got the solution. Here’s how:"
                bgColor="#ffffff"
                transparency={0}
                titleColor="#ffffff"
                textColor="#ffffff"
            />
            <TextContainer 
                title="Our Vision" 
                text="We envision a world where computing power is not limited by access or cost. Whether you’re a researcher, developer, or creative professional, everyone should have access to the computational resources they need to drive innovation forward."
                bgColor="#ffffff"
                transparency={1}
                titleColor="#000000"
                textColor="#000000"
            />
            <br />
            <TextContainer 
                title="What We Do" 
                text="Neuralperk connects people with idle laptops and computers to a network that utilizes their unused power for high-performance tasks like rendering graphics or processing AI/ML workloads. At the same time, those who need extra computational resources can access our powerful machines on demand—whether it’s for training deep learning models, running data-heavy simulations, or completing rendering jobs faster."
                bgColor="#ffffff"
                transparency={1}
                titleColor="#000000"
                textColor="#000000"
            />
            <br />
            <TextContainer 
                title="Why Choose Neuralperk?" 
                text="
                <ul>
                    <li>
                    <strong>Earn While Idle</strong><br>
                    Get paid by sharing your computer's power when you're not using it—passive income with zero effort.
                    </li>
                    <br>
                    <li>
                    <strong>Access High-Performance Computing</strong><br>
                    Need more power for your projects? Whether it's rendering complex visuals or running AI/ML algorithms, we provide scalable resources to fit your needs.
                    </li>
                    <br>
                    <li>
                    <strong>Secure & Efficient</strong><br>
                    Your data is always protected through our encrypted systems, ensuring a smooth and secure experience for both contributors and users.
                    </li>
                </ul>"
                bgColor="#ffffff"
                transparency={0}
                titleColor="#ffffff"
                textColor="#ffffff"
            />
            <TextContainer 
                title="Our Values" 
                text="
                
                <ul>
                    <li>
                    <strong>Innovation</strong><br>
                    Driving new possibilities by making high-performance computing more available.
                    </li>
                    <br>
                    <li>
                    <strong>Collaboration</strong><br>
                    Creating a community where resources are shared for the benefit of all.
                    </li>
                    <br>
                    <li>
                    <strong>Efficiency</strong><br>
                    Maximizing the use of idle computing power, reducing waste, and generating value.
                    </li>
                </ul>"
                bgColor="#ffffff"
                transparency={0}
                titleColor="#ffffff"
                textColor="#ffffff"
            />
            </section>

        </>
    );
}

export default About;
