import React, {useEffect, useContext, useState} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from "./components/Partials/Navbar"; // Ensure correct casing of component import
import Footer from "./components/Partials/Footer"; 
import HomePage from './components/Home'; 
import ServicesPage from './components/Services';
import AboutPage from './components/About';
import NotFound from './components/NotFound';

import ScrollToTop from './scrollToTop';

import { websiteContext } from './WebsiteContext';
import Modal from './components/ApplicationDownloadModel';

function App() {
    const {AppDownloadModalState} = useContext(websiteContext);
    const [isModalVisible, changeModalVisibility] = useState(false);

    useEffect(() => {
        // console.log("Updating AppDownloadModalState"); 
        // console.log(AppDownloadModalState);
        changeModalVisibility(AppDownloadModalState);
    }, [AppDownloadModalState]);

    return (
        <>
        {isModalVisible && (<Modal />)}
        <Router>
            {/* Render modal if isModalVisible is true */}
            <Navbar /> {/* Navbar will be constant on all pages */}     

            <ScrollToTop/>
            <Routes>
                {/* Define routes for different components */}
                <Route path="/" element={<HomePage />} />
                <Route path="/home" element={<HomePage />} />
                <Route path="/services" element={<ServicesPage />} />
                <Route path="/about" element={<AboutPage />} />
                {/* <Route path="/privacyPolicy"> <PrivacyPolicy /> </Route> */}
                {/* Add a catch-all route for 404 page */}
                <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer /> {/* Footer will be constant on all pages */}
        </Router>
        </>
    );
}

export default App;
